<template>
    <div v-if="loading">
        <div class="container vh-50 d-flex align-items-center justify-content-center">
            <div class="p-xl-5 p-md-3">
                <div class="d-flex flex-column text-center pt-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading">
        <div class="container-fluid pt-5 px-0">
            <div class="row" v-if="courses.length > 0">
                <div class="col-lg-3 col-md-4 col-sm-4 col-6 mb-3 d-flex align-items-stretch justify-content-center px-0" v-for="course of courses" :key="course.id">
                    <a :href="`/course/${course.id}`" class="text-decoration-none">
                        <div class="card rounded-0 border-0">
                            <span class="circle-image">
                                <picture>
                                    <source media="(max-width:480px)" :srcset="course.mobileImageUrl" @error="imageLoadOnError">
                                    <img :src="course.desktopImageUrl" :alt="course.name" class="img-fluid" @error="imageLoadOnError">
                                </picture>
                            </span>
                            <div class="card-body d-flex flex-column pt-4">
                                <!--<h2 class="card-title text-center text-uppercase">{{course.name}}</h2>-->
                                <p class="card-text mb-4 text-center js-read-smore">
                                    <strong class="fw-bolder">{{course.name}}</strong> {{course.description}} <!--<a :href="`/course/${course.id}`" class="link">Read More</a>-->
                                    <div class="link">Read More</div>
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div v-if="courses.length === 0 && !loading">
                <div class="container vh-50 d-flex align-items-center justify-content-center">
                    <div class="p-xl-5 p-md-3">
                        <div class="d-flex flex-column text-center pt-5">
                            <h4 class="text-center">No Courses to display</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: "Courses",
        components: {
        },
        data() {
            return {
                loading: false,
                courses: [] as any,
                isLoaded: false
            };
        },
        methods: {
            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1671006754/pawpedia/static/image_not_available_tqbecm.png"
            },
            checkUserExists() {
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/courses';
                } 
            },
        },
        async mounted() {
            this.loading = true;
            await axios
            .get(apiUrl + "/courses/summarized")
            .then((response) => {              
                this.loading = false;
                this.courses = response.data.items;
                })
            .catch((error) => {
                return error;
            });
        },
        created() {
            this.checkUserExists();
        },
    })
</script>

<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
