<template>
    <Form class="no-lr-padding" @submit="onSubmit" :validation-schema="schema"  v-slot="{ errors }">
        <div class="row mb-3">
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <Field type="email" id="name" v-model="user.emailAddress" name="emailaddress" class="form-control rounded-0"  :class="{ 'is-invalid': errors.emailaddress }" />
                    <label for="name">Email Address <span :class="{ 'error': errors.emailaddress }">*</span></label>
                    <div class="invalid-feedback">{{errors.emailaddress}}</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-3">
                <div v-if="message" class="alert alert-danger rounded-0" role="alert">
                    {{ message }}
                </div>
            </div>
        </div>       
        <button class="btn btn-primary rounded-0 px-4 pb-2" :disabled="loading"  v-show="isHidden">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Send Recovery Link</span>
        </button>

    </Form>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';

    export default defineComponent({
        name: 'LoginApp',
        components: {
            Form,
            Field
        },
        data() {
            const schema = yup.object().shape({
                emailaddress: yup.string().required('Email is required').email('Email is invalid'),
            });

            return {
                user: {
                    emailAddress: ''
                },
                loading: false,
                isHidden: true,
                message: '',
                schema,
            };
        },
        methods: {
            onSubmit() {
                this.loading = true;
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                };
                return axios.post(apiUrl + '/passwords/forgot', this.user, requestOptions)
                    .then((response) => {
                          this.isHidden = false;
                        this.message = "Check your inbox to reset your password";
                          
                    }, (error) => {
                           this.loading = false;                       
                        this.message = error.response.data.title
                    });
            },
        }

    });
</script>