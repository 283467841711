<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="d-flex justify-content-center flex-column align-items-center">
                    <h5 class="text-uppercase text-medium bold-text text-mobile-center">
                        Dog Courses
                    </h5>
                    <div id="publicDogCourse">
                        <ul class="list-unstyled text-mobile-center">
                            <li class="text-medium" v-for="course of courses" :key="course.id">
                                <a :href="`/course/${course.id}`" v-if="course.species == 'Canine'">{{course.name}}</a>
                            </li>
                        </ul>
                    </div>
                    <div id="schoolDogCourse">
                        <ul class="list-unstyled text-mobile-center">
                            <li class="text-medium" v-for="course of courses" :key="course.id">
                                <a :href="`/school#/course/${course.id}`" v-if="course.species == 'Canine'">{{course.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>                
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-center flex-column align-items-center">
                    <h5 class="text-uppercase text-medium bold-text bold-text text-mobile-center">Cat Courses</h5>
                    <div id="publicCatCourse">
                        <ul class="list-unstyled quick-links text-mobile-center text-medium">
                            <li class="text-medium" v-for="course of courses" :key="course.id">
                                <a :href="`/course/${course.id}`" v-if="course.species == 'Feline'">{{course.name}}</a>
                            </li>
                        </ul>
                    </div>
                    <div id="schoolCatCourse">
                        <ul class="list-unstyled quick-links text-mobile-center text-medium">
                            <li class="text-medium" v-for="course of courses" :key="course.id">
                                <a :href="`/school#/course/${course.id}`" v-if="course.species == 'Feline'">{{course.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-center  flex-column align-items-center">
                    <h5 class="text-uppercase text-medium bold-text text-mobile-center">
                        <a href="/contact">Contact Us</a>
                    </h5>
                    <h5 class="text-uppercase text-medium bold-text text-mobile-center">
                        <a href="/glossary">Glossary</a>
                    </h5>
                </div>
            </div>
          </div>
    </div>

</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: "Courses",
        components: {
        },
        data() {
            return {
                loading: false,
                courses: [] as any
              };
        },       
        async mounted() {
            this.loading = true;
            await axios
            .get(apiUrl + "/courses/summarized")
            .then((response) => {
                 this.loading = false;
                this.courses = response.data.items;
                })
            .catch((error) => {    
                this.loading = false;
                return error;
            });
        },
    })
</script>

<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
