<template>
    <div class="bg-blue">
        <div class="jumbotron jumbotron-fluid">
            <div class="container mx-auto px-sm-0 px-md-0">
                <div class="small-banner">
                    <div class="wrap">
                        <div class="heading-wrap">
                            <h3 class="text-uppercase" v-if="!loading">{{course.courseType}}</h3>
                            <h1 class="text-uppercase">
                                ALL THINGS
                                <span v-if="course.species == 'Feline'">CAT</span>
                                <span v-if="course.species == 'Canine'">DOG</span>
                            </h1>
                        </div>
                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675102514/pawpedia/static/all-things-dog-banner_fs9jxm.png" alt="Courses" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container">
        <div v-if="loading" class="text-center vh-50 p-5 m-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
        </div>
        <div class="row" v-if="!loading">
            <div class="course py-5">
                <h3 class="text-uppercase">{{course.name}}</h3>
                <h4 class="pt-2 pb-3">
                    {{course.description}}
                </h4>
                <div class="course full-width px-0 desktop">
                    <div class="course-wrapper full-width">
                        <div class="filters pb-4">
                            <div class="filter-button" v-for="(theme, i) in course.themes" :key="theme.id"
                                 :class="[selected == theme.id ? 'active':'']" @click="makeActive(theme.id)">
                                <picture>
                                    <source media="(max-width:480px)" :srcset="theme.mobileImageUrl" @error="imageLoadOnError" v-if="theme.mobileImageUrl.length > 0">
                                    <img :src="theme.desktopImageUrl" :alt="theme.name" class="category-icon" width="250" @error="imageLoadOnError">
                                </picture>
                                {{theme.name}}
                            </div>
                        </div>
                        <div class="content">
                            <div v-if="loadingModules" class="text-center vh-50 p-5 m-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                            </div>
                            <div class="item" v-for="(theme, i) in course.themes" :key="theme.id" else>
                                <div class="module-wrapper" v-for="(module , i) in theme.modules" :key="module.id" v-if="selected == theme.id">
                                    <div v-if="module.name">
                                        <h4 class="text-uppercase">{{module.name}}</h4>
                                        <div class="lessons d-flex align-content-between" v-for="(lesson, i) in module.lessons" :key="lesson.id">
                                            <div class="lesson-left d-flex align-items-center">
                                                <div v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                    <div v-if="content.fileType == 'Audio'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/audio_g9bok2.png" alt="audio" />
                                                    </div>
                                                    <div v-if="content.fileType == 'Pdf'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/pdf_tgjqoo.png" alt="pdf" />
                                                    </div>
                                                    <div v-if="content.fileType == 'Video'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/video_cnedn8.png" alt="video" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5>{{lesson.name}}</h5>
                                                    <h6 v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                        <span v-show="content.fileType == 'Audio'">5 minute listen</span>
                                                        <span v-show="content.fileType == 'Pdf'">5 minute read</span>
                                                        <span v-show="content.fileType == 'Video'">5 minute video</span>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div>
                                                <button class="red text-uppercase" @click="dialogState = true" v-if="!this.userLogged">Let's Go</button>
                                                <button class="red text-uppercase" @click="goToThisLesson(lesson.id)" v-if="this.userLogged">Let's Go</button>
                                            </div>
                                            <GDialog v-model="dialogState">
                                                <div class="form-wrapper">
                                                    <div class="container">
                                                        <Form class="no-lr-padding" @submit="handleLogin(user,lesson.id)" :validation-schema="schema" v-slot="{ errors }">
                                                            <div class="details">
                                                                <button class="close"
                                                                        @click="dialogState = false">
                                                                    x
                                                                </button>
                                                                <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                                                                <div class="dmessage">
                                                                    <div class="redColour" v-if="dmessage">
                                                                        {{ dmessage }}
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <Field type="email" v-model="user.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                                                            <label for="name">Email Address *</label>
                                                                            <div class="invalid-feedback">{{errors.username}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <Field type="password" v-model="user.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                                                            <label for="name">Password *</label>
                                                                            <div class="invalid-feedback">{{errors.password}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="buttons">
                                                                <div>
                                                                    <button v-show="!loading" type="submit" class="btn btn-primary rounded-0 px-4">
                                                                        Login
                                                                    </button>
                                                                    <button v-show="loading" class="btn btn-primary rounded-0 px-4">
                                                                        Submitting
                                                                    </button>
                                                                </div>
                                                                <div class="divider"><span>OR</span></div>
                                                                <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                                                                <div><a href="/forgotpassword" class="text-medium">Forgot password?</a></div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </GDialog>
                                        </div>
                                    </div>
                                </div>

                                <div class="module-wrapper" v-for="(module, i) in theme.modules" :key="module.id" v-if="selected == theme.id && !theme.modules">
                                    No Data to display
                                </div>
                            </div>

                            <!--<div>
                    <div class="quiz">
                        <h4 class="text-uppercase">TAKE THE QUIZ!</h4>
                        <h5>Complete all lessons to unlock the quiz!</h5>
                    </div>
                </div>-->
                        </div>
                    </div>
                </div>


               
            
            
            
            
               
                
                
              
                
                
                
                
                <div class="course full-width px-0 mobile">
                    <div class="course-wrapper full-width">
                        <div class="filters pb-4">
                            <div class="filter-button" v-for="(theme, i) in course.themes" :key="theme.id"
                                 :class="[selected == theme.id ? 'active':'']" @click="makeActive(theme.id)">
                                <picture>
                                    <source media="(max-width:480px)" :srcset="theme.mobileImageUrl" @error="imageLoadOnError" v-if="theme.mobileImageUrl.length > 0">
                                    <img :src="theme.desktopImageUrl" :alt="theme.name" class="category-icon" width="250" @error="imageLoadOnError">
                                </picture>
                                {{theme.name}}
                            </div>
                        </div>
                        <div class="content">
                            <div v-if="loadingModules" class="text-center vh-50 p-5 m-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                            </div>
                            <div class="item" v-for="(theme, i) in course.themes" :key="theme.id" else>
                                <div class="module-wrapper" v-for="(module , i) in theme.modules" :key="module.id" v-if="selected == theme.id">
                                    <div v-if="module.name">
                                        <h4 class="text-uppercase">{{module.name}}</h4>
                                        <div class="lessons d-flex align-content-between" v-for="(lesson, i) in module.lessons" :key="lesson.id">
                                            <div class="lesson-left d-flex align-items-center">
                                                <div v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                    <div v-if="content.fileType == 'Audio'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/audio_g9bok2.png" alt="audio" />
                                                    </div>
                                                    <div v-if="content.fileType == 'Pdf'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/pdf_tgjqoo.png" alt="pdf" />
                                                    </div>
                                                    <div v-if="content.fileType == 'Video'">
                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1675365592/pawpedia/static/video_cnedn8.png" alt="video" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5>{{lesson.name}}</h5>
                                                    <h6 v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                        <span v-show="content.fileType == 'Audio'">5 minute listen</span>
                                                        <span v-show="content.fileType == 'Pdf'">5 minute read</span>
                                                        <span v-show="content.fileType == 'Video'">5 minute video</span>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div>
                                                <button class="red text-uppercase" @click="dialogState = true" v-if="!this.userLogged">Let's Go</button>
                                                <button class="red text-uppercase" @click="goToThisLesson(lesson.id)" v-if="this.userLogged">Let's Go</button>
                                            </div>
                                            <GDialog v-model="dialogState">
                                                <div class="form-wrapper">
                                                    <div class="container">
                                                        <Form class="no-lr-padding" @submit="handleLogin(user,lesson.id)" :validation-schema="schema" v-slot="{ errors }">
                                                            <div class="details">
                                                                <button class="close"
                                                                        @click="dialogState = false">
                                                                    x
                                                                </button>
                                                                <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                                                                <div class="dmessage">
                                                                    <div class="redColour" v-if="dmessage">
                                                                        {{ dmessage }}
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <Field type="email" v-model="user.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                                                            <label for="name">Email Address *</label>
                                                                            <div class="invalid-feedback">{{errors.username}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <Field type="password" v-model="user.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                                                            <label for="name">Password *</label>
                                                                            <div class="invalid-feedback">{{errors.password}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="buttons">
                                                                <div>
                                                                    <button v-show="!loading" type="submit" class="btn btn-primary rounded-0 px-4">
                                                                        Login
                                                                    </button>
                                                                    <button v-show="loading" class="btn btn-primary rounded-0 px-4">
                                                                        Submitting
                                                                    </button>
                                                                </div>
                                                                <div class="divider"><span>OR</span></div>
                                                                <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                                                                <div><a href="/forgotpassword" class="text-medium">Forgot password?</a></div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </GDialog>
                                        </div>
                                    </div>
                                </div>

                                <div class="module-wrapper" v-for="(module, i) in theme.modules" :key="module.id" v-if="selected == theme.id && !theme.modules">
                                    No Data to display
                                </div>
                            </div>

                            <!--<div>
                <div class="quiz">
                    <h4 class="text-uppercase">TAKE THE QUIZ!</h4>
                    <h5>Complete all lessons to unlock the quiz!</h5>
                </div>
            </div>-->
                        </div>
                    </div>
                </div>























            </div>
        </div>
    </div>



    
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import axios from 'axios'
    import apiUrl from '../scripts/BaseUrl';
  
    import { GDialog } from 'gitart-vue-dialog'
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';

    /* import Swal from 'sweetalert2';*/

    //const user = JSON.parse(localStorage.getItem('user') || '');

    export default defineComponent({
        name: "course",
        components: {
            GDialog,
            Form,
            Field,
            axios
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!"),
            });
            return {
                course: '',
                desktopAsset: undefined,
                mobileAsset: undefined,
                selected: '',
                selectedThemeId: '',
                message: '',
                loadingModules: false,
                modules: [] as any,
                lessonContent: [] as any,
                loading: false,               
                dialogState: false, 
                userLogged: '',
                dmessage: '',
                user: {
                    userName: "",
                    password: ""
                },
                schema,
            };
        },
        methods: {   

            handleLogin(user: any, lessonId: any) {
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: user.username,
                        password: user.password
                    }
                }
                const authToken = window.btoa(user.username + ':' + user.password)
                return axios.post(apiUrl + '/accounts', this.user, authData)
                .then((response) => {
                    this.dialogState = false;
                    let user = response.data;
                    user.token = authToken;
                    localStorage.setItem('user', JSON.stringify(user));

                    if (user.deactivatedAt) {
                        this.dmessage = 'User have been Deactivated';
                    } else {
                        window.location.href = '/school#/lesson/' + lessonId;
                    }

                }, (error) => {
                    this.loading = false;
                    if (error.response) {
                        if (error.response.data) {
                            this.message = error.response.data;
                        } else {
                            this.message = 'An error occured. contact your system administrator';
                        }

                    } else {
                        this.message = error.response.data.message;
                    }
                });
            }, 


           imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1671006754/pawpedia/static/image_not_available_tqbecm.png"
            },
            async displayModules(themeId: any) {        
                this.loadingModules = true;
                return axios.get(apiUrl + '/themes/' + themeId)
                .then((response) => {
                    this.loadingModules = false;
                    this.modules = response.data.modules;
                    return response;
                })
                .catch(error => {
                    this.loadingModules = false;
                    return error;
                })
               
            },
            makeActive(themeId: any) {
                this.selected = themeId;        
            },
            goToThisLesson(lessonId:any) {
                window.location.href = '/school#/lesson/' + lessonId;
            }
        },
          async mounted() {
           this.loading = true;
           const courseId = location.pathname.split('/')[2]
           await axios
            .get(apiUrl + '/courses/' + courseId + '/summarized')
            .then((response) => {
                this.loading = false;
                this.course = response.data;
                this.desktopAsset = response.data.desktopImageUrl;
                this.mobileAsset = response.data.mobileImageUrl;
                this.selectedThemeId = response.data.themes[0].id;
                this.userLogged = JSON.parse(localStorage.getItem('user') || '');
            })
            .catch((error) => {
                this.loading = false;
                return error;

            });

      
       },
    });
</script>


