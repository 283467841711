<template>  
    <div class="pb-5">   
        <div v-if="loading">
            <div class="container vh-50 d-flex align-items-center justify-content-center">
                <div class="p-xl-5 p-md-3">
                    <div class="d-flex flex-column text-center pt-5">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div class="faq-container" v-if="faqs.length > 0">
                <div class="faqs">
                    <Faq v-for="(faq, i) in faqs"
                         :faq="faq"
                         :index="i"
                         :key="i"
                         :open="faq.open"
                         @toggle="toggleOpen" />
                </div>
                <div class="pb-2 pt-3 d-flex justify-content-end">                   
                    <Pagination :skip="faqsFilterStore.skip" :take="faqsFilterStore.take" :skipped="faqsFilterStore.result.skipped" :taken="faqsFilterStore.result.taken" :total="faqsFilterStore.result.total" @prevPage="faqsFilterStore.setPrevPage()" @nextPage="faqsFilterStore.setNextPage()" />
                </div>
            </div>
            <div v-if="faqs.length === 0 && !loading">
                <div class="container vh-50 d-flex align-items-center justify-content-center">
                    <div class="p-xl-5 p-md-3">
                        <div class="d-flex flex-column text-center pt-5">
                            <h4 class="text-center">No FAQS to display</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Faq from './components/Faqs.vue';
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    import { defineComponent } from 'vue';
    //pagination
    import Pagination from '../../components/Pagination.vue';

    //store
    import { mapStores } from 'pinia';
    import { useFilterStore } from './store/filter-store';
    import type { FilterModel } from './store/filter-model';

    export default defineComponent({
        name: 'FAQS',
        components: { Faq, Pagination },
        data() {
            return {
                loading: false,
                faqs: [] as any            
           }
        },

        methods: {
            toggleOpen(index: any) {
                this.faqs = this.faqs.map((faq: any, i: any) => {
                    if (index === i) {
                        faq.open = !faq.open;
                    } else {
                        faq.open = false;
                    }
                    return faq;
                })

            },
            async loadFaqs(filter?: FilterModel) {
                this.loading = true;               
                const response = await axios.get(apiUrl + '/faqs/list', { params: filter });
                this.faqs = response.data.items;
                this.loading = false;


                // update filter
                const { skipped, taken, total } = response.data;
                this.faqsFilterStore.setResult(total, skipped, taken);
            },
            checkUserExists() {
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/faqs';
                }
            },
        },
        async mounted() {
            this.loading = true;
            this.loadFaqs(this.faqsFilterStore.$state);
      
            this.faqsFilterStore.$subscribe((mutation, state) => {
                if (!state.isUpdate) {
                    return;
                }
                this.loadFaqs(state);
            });
        }, 
        created() {
          this.checkUserExists();
        },
        computed: {
            ...mapStores(useFilterStore('faqsFilter')),
        },
    });
</script>