<template>
    <Form class="no-lr-padding" @submit="handleLogin" :validation-schema="schema">
        <div class="dmessage">
            <div class="redColour" v-if="dmessage">
                {{ dmessage }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <Field type="email" id="name" v-model="user.userName" name="username" class="form-control rounded-0" />
                    <label for="name">Email Address *</label>
                </div>
                <div><ErrorMessage name="username" class="error-feedback" /></div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <Field type="password" id="password" v-model="user.password" name="password" class="form-control rounded-0" />
                    <label for="name">Password *</label>
                </div>
                <div><ErrorMessage name="password" class="error-feedback" /></div>
            </div>
        </div>

        <button class="btn btn-primary rounded-0 px-4" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Login</span>
        </button>
        <div class="row mt-4" v-show="message">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <div class="alert alert-danger rounded-0" role="alert">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 col-md-6">
                <div class="form-group text-align">
                    <label>
                        <a href="/forgot-password" class="text-medium">Forgot password?</a>
                    </label>
                </div>
            </div>
        </div>
    </Form>

</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import * as yup from 'yup';
    import axios from 'axios';
    import Swal from 'sweetalert2';
    import apiUrl from '../scripts/BaseUrl';

    export default defineComponent({
        name: 'LoginApp',
        components: {
            Form,
            Field,
            ErrorMessage
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!")
            });

            return {
                user: {
                    userName: '',
                    password: ''
                },
                loading: false,
                message: '',
                schema,
                dmessage: '',

            };
        },
        methods: {

            handleLogin(user: any) {
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: user.username,
                        password: user.password
                    }
                }
                const authToken = window.btoa(user.username + ':' + user.password)
                return axios.post(apiUrl + '/accounts', this.user, authData)
                    .then((response) => {             
                        let user = response.data;
                        user.token = authToken;              
                        localStorage.setItem('user', JSON.stringify(user));                        
  
                        if ( user.role.toLowerCase() == 'SuperAdmin'.toLowerCase()) {
                            if (user.deactivatedAt) {
                                this.dmessage = 'User have been Deactivated';
                            } else {
                                window.location.href = '/admin';
                            }
                        } else if (user.role.toLowerCase() == 'Admin'.toLowerCase()) {
                            if (user.deactivatedAt) {
                                this.dmessage = 'User have been Deactivated';
                            } else {
                                window.location.href = '/admin#/courses';
                            }
                          
                        } else if (user.role.toLowerCase() == 'User'.toLowerCase()) {                           
                            if (user.deactivatedAt) {
                                this.dmessage = 'User have been Deactivated';
                            } else {                                
                                if (user.courseProgress) {                               
                                    window.location.href = '/school#/course/' + user.courseProgress.id;
                                    this.loading = false;

                                } else {    
                                    const lessonId = sessionStorage.getItem('LessonID');
                                    if (lessonId) {
                                        window.location.href = '/school#/lesson/' + lessonId;
                                    } else {
                                        window.location.href = '/school#/courses';                     
                                    }      
                                }

                            }
                        } else {
                            const lessonId = sessionStorage.getItem('LessonID');
                            if (lessonId) {
                                window.location.href = '/school#/lesson/' + lessonId;
                            } else {
                                window.location.href = '/login';                
                            }      

                        }  

                    },(error) => {
                        this.loading = false;
                        if (error.response) {                         
                            if (error.response.data) {
                                this.message = error.response.data;                   
                            } else {
                                this.message = 'Username or password is incorrect';
                            }
                           
                        } else {                      
                            this.message = 'This email address has not been registered yet, register here';
                        }
                  });
            },
        }

    });
</script>