<template>
    <div v-if="isLoading" class="text-center p-5 m-5 loader">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
    </div>
    <div v-if="!isLoading">     
        <Form class="no-lr-padding" :validation-schema="schema" v-slot="{ errors }">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group mb-4">
                        <Field type="password" id="password" name="password" v-model="model.password" class="form-control rounded-0" autocomplete="off" :class="{ 'is-invalid': errors.password }" />
                        <label for="password" class="regular">Password <span>*</span></label>
                        <div class="invalid-feedback">{{errors.password}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group mb-4">
                        <Field type="password" id="rpassword" name="retypePassword" v-model="model.confirmedPassword" class="form-control rounded-0" autocomplete="off" :class="{ 'is-invalid': errors.retypePassword }" />
                        <label for="rpassword" class="regular">Retype Password <span>*</span></label>
                        <div class="invalid-feedback">{{errors.retypePassword}}</div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="message">
                <div class="col-12 col-md-6 mb-4 ">
                    <div class="form-group">
                        {{ message }}
                    </div>
                    <div><ErrorMessage name="rpassword" class="error-feedback" /></div>
                </div>
            </div>
            <button class="btn btn-primary rounded-0 px-4" :disabled="loading" v-on:click="onSubmit">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span>Save</span>
            </button>
        </Form>
    </div>

</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';

    export default defineComponent({
        name: "ResetPassword",
        components: {
            Form,
            Field
        },
        data() {
            const schema = yup.object().shape({
                password: yup.string()
                    .min(6, 'Password must be at least 6 characters')
                    .required('Password is required'),
                retypePassword: yup.string()
                    .oneOf([yup.ref('password'), null], 'Passwords must match')
                    .required('Retype Password is required'),
            });

            return {
                model: {
                    password: '',
                    confirmedPassword: '',
                    passwordFieldType: 'password'
                },
                data: '',
                isLoading: false,
                errorMessage:'',
                loading: false,
                message: "",
                schema
            };
        },
        methods: {      
            onSubmit() {
                this.loading = true; 
                const token = location.pathname.split('/')[2];
                return axios.post(apiUrl + '/passwords/reset?token='+ token, this.model)
                    .then((response) => { 
                       window.location.href = "/reset-password/thankyou";
                        return response;
                    }, (error) => {
                        this.loading = false;                
                        this.message = error;
                    });
            },
        },
        async mounted() {
            this.isLoading = true;
            const token = location.pathname.split('/')[2];
   
           await axios
             .get(apiUrl + '/passwords/validate-token?token=' + token)
            .then((response) => {
                this.isLoading = false;
                this.data = response.data;
            })
            .catch((error) => {
                this.isLoading = false;
                this.errorMessage = error.token;
                window.location.href = "/forgot-password";                    
                   
            });
        }, 
    });
</script>