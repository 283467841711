<template>
    <div :class="open ? 'faq open' : 'faq'"
         @click="$emit('toggle', index)">
        <div v-if="faq.unregistered">
            <div class="question-container">
                <h2 class="question">{{ faq.question }}</h2>
                <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1669025303/pawpedia/static/faq-arrow_cd0kwa.svg" alt="arrow" class="faq-arrow">
            </div>
            <div class="answer">
                <div>{{ faq.answer }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    export default {
        props: ["faq", "index", "open"],
    };
</script>