﻿//export default function scrollToTop() {
//    const scrollBtn = document.querySelector(".scrollToTopBtn");
//    const documentElement = document.documentElement;

//    function scrollUp() {
//        documentElement.scrollTo({
//            top: 0,
//            behavior: "smooth"
//        });
//    }
//    if (scrollBtn !== null) {
//        scrollBtn.addEventListener("click", scrollUp);
//    }
//}


export const scrollBtn = document.querySelector(".scrollToTopBtn");
export const documentElement = document.documentElement;
export default function scrollUp() {
    documentElement.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}
