<template>
    <div class="container-fluid px-0">
        <div v-if="loading" class="text-center p-5 m-5 vh-50">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
        </div>
        <div class="glossary-container" else>
            <div class="filters-container">
                <GlossaryLetters :loading="letterStatus === statuses.loading" :letters="letters" :selectedLetter="letter" @select="selectLetter" />

                <input type="text" v-on:input="searchChange" placeholder="Search" id="search-input" v-model="searchValue" />

                <button class="closeBtn border-0 bg-white position-absolute" v-if="search != ''" v-on:click="clearFilter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.793" height="15.072" viewBox="0 0 13.793 15.072">
                        <g id="Group_365" data-name="Group 365" transform="translate(-103.755 -939.333)">
                            <line id="Line_9" data-name="Line 9" x1="12.303" y2="13.738" transform="translate(104.5 940)" fill="none" stroke="#707070" stroke-width="2" />
                            <line id="Line_10" data-name="Line 10" x1="12.303" y1="13.738" transform="translate(104.5 940)" fill="none" stroke="#707070" stroke-width="2" />
                        </g>
                    </svg>
                </button>
            </div>

            <div v-if="status !== statuses.loading">
                <Pagination :skip="skip" :take="take" :skipped="result.skipped" :taken="result.taken" :total="result.total" @prevPage="setPrevPage()" @nextPage="setNextPage()" />
            </div>
            <!--<span v-if="status === statuses.loading" class="spinner-border spinner-border-sm"></span>-->
            <div v-if="status === statuses.loading" class="container text-center  p-5 m-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
            </div>

            <div class="items-container mt-3">
                <div class="items" v-bind:key="item.title" v-for="item in list">
                    <div class="items-wrapper">
                        <h2>{{item.title}}</h2>
                        <p>{{item.description}}</p>
                    </div>
                </div>
                <div class="items-container items-wrapper" v-if="list.length === 0 && status !== statuses.loading">
                    <h4 class="py-5">Sorry we didn't find any results matching this search</h4>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import GlossaryLetters from '../../components/GlossaryLetters.vue';
    import Pagination from '../../components/Pagination.vue';

    //store
    import { mapState, mapActions } from 'pinia';
    import { useGlossaryStore } from '../../stores/glossary/glossary-store';
    import { useFilterStore } from '../../stores/common/filter-store-glossary';

    const filterStore = useFilterStore('glossary-filter');
    const glossaryStore = useGlossaryStore('glossary', 'glossary-filter');
    let searchTimer: any;

    export default defineComponent({
        name: 'Glossary',
        components: {
            GlossaryLetters,
            Pagination,
        },
        data() {
            return {
                searchValue: '',
            }
        },
        computed: {
            ...mapState(glossaryStore, ['letters', 'list', 'status', 'lettersStatus', 'statuses']),
            ...mapState(filterStore, ['letter', 'search', 'skip', 'take', 'result']),
        },
        methods: {
            ...mapActions(glossaryStore, ['loadLetters', 'load']),
            ...mapActions(filterStore, ['setLetter', 'setSearch', 'setPrevPage', 'setNextPage']),
            selectLetter(letter: string) {
                if (letter === this.letter) {
                    this.setLetter('');
                } else {
                    this.setLetter(letter);
                }
            },
            searchChange(event: any) {
                clearTimeout(searchTimer);

                searchTimer = setTimeout(() => {
                    this.setSearch(event.target.value);
                }, 500);
            },
            clearFilter() {
                this.setSearch('');
                this.searchValue = '';
            }
        },
        mounted() {
            this.loadLetters();
            this.load(filterStore().$state);
            filterStore().$subscribe((mutation, state) => {
                if (!state.isUpdate) {
                    return;
                }
                this.load(state);
            });
        },

    });
</script>
