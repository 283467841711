<template>
    <div v-if="loading" class="text-center p-5 m-5">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
    </div>
    <div>
        <div class="container" v-if="coaches.length > 0 && !loading">
            <div class="card-group vgr-cards">
                <div class="card" v-for="(coach,index) in coaches" :key="coach.id" :id="'coach-'+coach.id">
                    <div class="card-img-body">
                        <span class="circle-image">
                            <picture>
                                <source media="(max-width:480px)" :srcset="coach.mobileImageUrl" @error="imageLoadOnError" v-if="coach.mobileImageUrl.length>0">
                                <img :src="coach.desktopImageUrl" :alt="coach.firstName" class="img-fluid" @error="imageLoadOnError">
                            </picture>
                        </span>
                    </div>
                    <div class="card-body">
                        <h2 class="card-title text-uppercase">{{coach.fullname}}</h2>
                        <h3>{{coach.qualification}}</h3>
                        <p class="card-text desktop">
                            {{coach.bio}}
                        </p>
                        <p class="card-text mobile">
                            <span v-if="!readMore[index]">{{coach.bio.substring(0, 80)}}</span>
                            <span v-if="readMore[index]">{{coach.bio}}</span>
                            <span @click="showMore(index)" v-if="!readMore[index] && coach.bio.length > 80" class="read-more">
                                ...<span class="cursor">Read more</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="backtotop pb-5">
                <div class="text-center">
                    <button class="btn btn-primary rounded-0 text-uppercase" @click="nextPage()" v-if="coaches.length < total">load More</button>
                </div>
            </div>
            <div class="backtotop pb-5">
                <div class="text-center">
                    <button class="btn btn-primary rounded-0 text-uppercase scrollToTopBtn" @click="scrollTop">Back to top</button>
                </div>
            </div>
        </div>
        <div v-if="coaches.length === 0 && !loading">
            <div class="container vh-50 d-flex align-items-center justify-content-center">
                <div class="p-xl-5 p-md-3">
                    <div class="d-flex flex-column text-center pt-5">
                        <h4 class="text-center">No Coaches to display</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: 'Coaches',
        components: {
        },       
        data() {
            return {
                loading: false,
                coaches: [] as any,
                taken: 0,
                skipped: 0,
                takenEnd: 0,
                takenStart: 0,
                total: 0,
                totalPages: 0,        
                stepTaken: 0,
                pagedModel: {
                    orderBy: "",
                    searchTerm: "",
                    skip: 0,
                    take: 3
                },       
                readMore: [false],
            };
        },       
        methods: {
            showMore(index: any) {
                this.readMore[index] = true;
            }, 
            showLess(index: any) {
                this.readMore[index] = false;
            },    
            scrollTop() {
                let element = document.getElementById("topDiv");
                let top = element?.offsetTop;
                window.scrollTo(0, 0);
            },
            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1670967527/pawpedia/static/placeholder-profile_mxc0hq.jpg"
            },
            getCoaches() {
                this.loading = true;
                const params = "?skip=" + this.pagedModel.skip + "&take=" + this.pagedModel.take + "&orderBy=" + this.pagedModel.orderBy + "&searchTerm=" + this.pagedModel.searchTerm;
                if (location.hash) {
                    return axios.get(apiUrl + '/coaches/summarized')
                    .then((response) => {
                        this.loading = false;
                        this.coaches = this.coaches.concat(response.data.items);
                        this.total = response.data.total;
                        this.stepTaken = this.taken + this.skipped;
                    })
                    .catch((error) => {
                        return error;
                    });
                } else {
                    return axios.get(apiUrl + '/coaches/summarized' + params)
                        .then((response) => {
                            this.loading = false;
                            this.coaches = this.coaches.concat(response.data.items);
                            this.total = response.data.total;
                            this.stepTaken = this.taken + this.skipped;
                        })
                        .catch((error) => {
                            return error;
                        });
                }
            },
            nextPage() {
                var skip = this.pagedModel.skip + this.pagedModel.take;
                if (skip <= this.total) {
                    this.pagedModel.skip = skip;
                    this.getCoaches();
                }
            },      
            scrollToDiv() {
                let hash = window.location.hash.substr(1);  
                //window.scrollTo({
                //    top: document.getElementById(hash)?.offsetTop,
                //    left: 0,
                //    behavior: "smooth",
                //});   
                let access = document.getElementById(hash)
                access?.scrollIntoView();
            },
        }, 
        mounted() {       
            this.getCoaches();
            this.nextPage(); 
            //window.addEventListener("load", e => setTimeout(this.scrollToDiv, 200));
            document.addEventListener("DOMContentLoaded", e => setTimeout(this.scrollToDiv, 1000));
        },
    })
</script>

<style>
    #coaches {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
