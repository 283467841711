﻿export default function videoPlayer() {
    const videos = document.getElementById("video") as HTMLVideoElement | null;
    const circlePlay = document.getElementById("circle-play-b");
    function toggleVideo() {
        if (videos?.paused || videos?.ended) {
            videos?.play();
        } else {
            videos?.pause();
        }
    }

    if (circlePlay !== null) {
        circlePlay.addEventListener("click", toggleVideo);
        videos?.addEventListener("playing", function () {
            circlePlay.style.opacity = "0";
        });
        videos?.addEventListener("pause", function () {
            circlePlay.style.opacity = "1";
        });
    }
}


