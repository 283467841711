<template>
    <button v-on:click="doSomething" class="btn btn-outline-primary bold-text rounded-0">Login</button>
</template>

<script lang="ts">
    import Swal from 'sweetalert2'
    export default {
        methods: {
            doSomething() {              
                (async () => {

                    const { value: formValues } = await Swal.fire({
                        showCloseButton: true,
                        html:
                            '<div style="border: 2px solid red">' +
                            '<h2>REGISTER OR LOGIN TO CONTINUE. IT\'S FREE!</h2>' +
                            '<div><label for="emailadd">Email Address *</label></div>' +
                            '<input type="email" placeholder="jonesferdinand@gmail.com" id="emailadd" class="swal2-input">' +
                            '<div><label for="pass">Password *</label></div>' +
                            '<input type="text" placeholder="******" id="pass" class="swal2-input">' +
                            '</div>' +
                            '<div>' +
                            '<div><a href="/register" class="btn btn-outline-primary bold-text rounded-0">Register</a></div>' +
                            '<div><a href="/login" class="btn btn-primary rounded-0 px-3">Login</a></div>' +
                            '<div><a href="/forgotpassword" class="text-medium">Forgot password?</a></div>' +
                            '</div>'
                        , focusConfirm: false,
                        preConfirm: () => {
                            return [
                                //document.getElementById('swal-input1').value!,
                                //document.getElementById('swal-input2').value!
                            ]
                        }
                    })

                    if (formValues) {
                        Swal.fire(JSON.stringify(formValues))
                    }

                })()



            }
        }

    }
</script>
<style>
    .overlay {
        position: absolute;
        inset: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        padding: 3rem 0.5rem;
        background: linear-gradient(rgba(0, 0, 0, 0.3) 36%, rgba(0, 0, 0, 0.65) 64%, rgb(0, 0, 0));
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.7);
    }

</style>
