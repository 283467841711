<template> 
    <div v-if="loading" class="text-center p-5 m-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
    </div>
    <div else>
        <div id="coursePublic">
            <div class="course-wrap">
                <div class="swiper-wrap" v-if="courses.length > 0">
                    <swiper :modules="modules"
                            :direction="horizontal"
                            :slides-per-view="4"
                            :space-between="10"
                            :slides-offset-before="0"
                            :slides-offset-ofter="0"
                            :watch-overflow="true"
                            :center-insufficient-slides="true"
                            :set-wrapper-size="true"
                            :navigation="swiperOptions.navigation"
                            :breakpoints="swiperOptions.breakpoints"
                            @swiper="onSwiper"
                            class="courses-container">
                        <swiper-slide v-for="course of courses" :key="course.id">
                            <a :href="`/course/${course.id}`" class="text-decoration-none">
                                <span class="circle-image">
                                    <picture>
                                        <source media="(max-width:480px)" :srcset="course.mobileImageUrl" @error="imageLoadOnError" v-if="course.mobileImageUrl.length>0">
                                        <img :src="course.desktopImageUrl" :alt="course.name" class="img-fluid" @error="imageLoadOnError">
                                    </picture>
                                </span>
                                <div class="py-3">
                                    <h3 class="text-uppercase">{{course.name}}</h3>
                                </div>
                            </a>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev courses-prev"></div>
                    <div class="swiper-button-next courses-next"></div>
                </div>
            </div> 
            <div v-if="courses.length == 0">
                <h4 class="text-center">No courses to display</h4>
            </div>
        </div>
        <div id="courseSchool">
            <div class="course-wrap">
                <div class="swiper-wrap" v-if="courses.length > 0">
                    <swiper :modules="modules"
                            :direction="horizontal"
                            :slides-per-view="4"
                            :space-between="10"
                            :slides-offset-before="0"
                            :slides-offset-ofter="0"
                            :watch-overflow="true"
                            :center-insufficient-slides="true"
                            :set-wrapper-size="true"
                            :navigation="swiperOptions.navigation"
                            :breakpoints="swiperOptions.breakpoints"
                            @swiper="onSwiper"
                            class="courses-container">
                        <swiper-slide v-for="course of courses" :key="course.id">
                            <a :href="`/school#/course/${course.id}`" class="text-decoration-none">
                                <span class="circle-image">
                                    <picture>
                                        <source media="(max-width:480px)" :srcset="course.mobileImageUrl" @error="imageLoadOnError" v-if="course.mobileImageUrl.length>0">
                                        <img :src="course.desktopImageUrl" :alt="course.name" class="img-fluid" @error="imageLoadOnError">
                                    </picture>
                                </span>
                            </a>
                            <div class="py-3">
                                <h3 class="text-uppercase">{{course.name}}</h3>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev courses-prev"></div>
                    <div class="swiper-button-next courses-next"></div>
                </div>
                <div v-if="courses.length == 0">
                    <h4 class="text-center">No courses to display</h4>
                </div>
            </div>
          </div>
        </div>
</template>
<script  lang="ts">
    import { defineComponent } from 'vue'
    import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    SwiperCore.use([Navigation, Pagination, A11y]);
    import axios from 'axios';
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: 'CoursesSlider',
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {    
                loading: false,
                courses: [] as any,
                courseName: "",
                advice: "",
                swiperOptions: {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        }
                    }
                }
            };
        }, 
        methods: {
            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1670967527/pawpedia/static/placeholder-profile_mxc0hq.jpg"
            }
        },
        async mounted() {
            this.loading = true;
            await axios
                .get(apiUrl + '/courses/summarized')
                .then((response) => {
                    this.loading = false;
                    this.courses = response.data.items;
                  
                })
                .catch((error) => {
                    return error;
                });
        }, 
    })
</script>

<style>
    #courses {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
