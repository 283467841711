<template>
    <div>
        <div class="bg-blue">
            <div class="jumbotron jumbotron-fluid">
                <div class="wrapper cpad">
                    <div class="container-fluid mx-auto px-sm-0 px-md-0">
                        <div class="small-banner">
                            <div class="wrap">
                                <div class="heading-wrap">
                                    <h3 class="text-uppercase" v-if="!loading">{{course.courseType}}</h3>
                                    <h1 class="text-uppercase">
                                        ALL THINGS
                                        <span v-if="course.species == 'Feline'">CAT</span>
                                        <span v-if="course.species == 'Canine'">DOG</span>
                                    </h1>
                                </div>
                                <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1689660882/pawpedia/static/HPN_PawPedia_Website_Universal_Header_sdqmf3.png" alt="Courses" class="img-fluid small" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="wrapper cpad">
            <div class="container-fluid px-0">
                <div v-if="loading" class="text-center vh-50 p-5 m-5">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                </div>
                <div class="row" v-if="!loading">
                    <div class="course py-5">
                        <h3 class="text-uppercase">
                            <Highlighter class="my-highlight"
                                         highlightClassName="highlight"
                                         :searchWords="keywords"
                                         :autoEscape="true"
                                         :textToHighlight="course.name" />
                        </h3>
                        <h4 class="pt-2 pb-3">
                            <Highlighter class="my-highlight"
                                         highlightClassName="highlight"
                                         :searchWords="keywords"
                                         :autoEscape="true"
                                         :textToHighlight="course.description" />
                        </h4>
                        <div class="course full-width course-desktop px-0">
                            <div class="course-wrapper full-width">
                                <div class="filters pb-4">
                                    <div class="filter-button p-lg-4 p-m-4 p-sm-3" v-for="(theme, i) in course.themes" :key="theme.id"
                                         :class="[selected == theme.id ? 'active':'']" @click="makeActive(theme.id)" v-show="theme.modules.length > 0">
                                        <picture>
                                            <source media="(max-width:480px)" :srcset="theme.mobileImageUrl" @error="imageLoadOnError" v-if="theme.mobileImageUrl.length > 0">
                                            <img :src="theme.desktopImageUrl" :alt="theme.name" class="category-icon" @error="imageLoadOnError">
                                        </picture>
                                        <Highlighter class="my-highlight"
                                                     highlightClassName="highlight"
                                                     :searchWords="keywords"
                                                     :autoEscape="true"
                                                     :textToHighlight="theme.name" />
                                    </div>
                                </div>
                                <div class="content">
                                    <div v-if="loadingModules" class="text-center vh-50 p-5 m-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                                    </div>
                                    <div class="item" v-for="(theme, i) in course.themes" :key="theme.id" v-if="!loadingModules">
                                        <div class="module-wrapper" v-for="(module , index) in theme.modules" :key="module.id" v-if="selected == theme.id">
                                            <div v-if="module.lessons.length > 0" class="V-accordion">
                                                <div class="V-accordion_trigger" :class="{active: open === index}" @click="toggleItem(index)">
                                                    <h4 class="text-uppercase">
                                                        <span class="mw-80">
                                                            <Highlighter class="my-highlight"
                                                                         highlightClassName="highlight"
                                                                         :searchWords="keywords"
                                                                         :autoEscape="true"
                                                                         :textToHighlight="module.name" />
                                                        </span>
                                                        <svg class="V-accordion_trigger-icon" width="40" height="12">
                                                            <polyline points="12,10 20,2 28,10" stroke="#44464B" stroke-width="2" fill="none"></polyline>
                                                        </svg>
                                                    </h4>
                                                    <p v-if="module.description">
                                                        <Highlighter class="my-highlight"
                                                                     highlightClassName="highlight"
                                                                     :searchWords="keywords"
                                                                     :autoEscape="true"
                                                                     :textToHighlight="module.description" />
                                                    </p>
                                                </div>
                                                <div class="V-accordion_body" v-if="open === index">
                                                    <div class="lessons d-flex align-content-between" v-for="(lesson, i) in module.lessons" :key="lesson.id">
                                                        <div class="lesson-left d-flex align-items-center">
                                                            <div v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                                <div v-if="content.fileType == 'Audio'">
                                                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/microphone_djfrek.svg" alt="audio" />
                                                                </div>
                                                                <div v-if="content.fileType == 'Pdf'">
                                                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/document_eoybse.svg" alt="pdf" />
                                                                </div>
                                                                <div v-if="content.fileType == 'Video'">
                                                                    <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/video_hzxeqf.svg" alt="video" />
                                                                </div>
                                                            </div>
                                                            <div class="pe-3">
                                                                <h5>
                                                                    <Highlighter class="my-highlight"
                                                                                 highlightClassName="highlight"
                                                                                 :searchWords="keywords"
                                                                                 :autoEscape="true"
                                                                                 :textToHighlight="lesson.name" />
                                                                </h5>
                                                                <h6 v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                                    <span v-show="content.fileType == 'Audio'">{{lesson.duration}} minute listen</span>
                                                                    <span v-show="content.fileType == 'Pdf'">{{lesson.duration}} minute read</span>
                                                                    <span v-show="content.fileType == 'Video'">{{lesson.duration}} minute video</span>
                                                                </h6>
                                                                <div class="copy">
                                                                    <Highlighter class="my-highlight"
                                                                                 highlightClassName="highlight"
                                                                                 :searchWords="keywords"
                                                                                 :autoEscape="true"
                                                                                 :textToHighlight="lesson.description" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button class="red text-uppercase" @click="openModal(course.id)">Let's Go</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="course full-width course-mobile px-0">
                            <div class="course-wrapper full-width parent pb-4">
                                <div class="filters">
                                    <div class="filter-button p-lg-4 p-md-4 p-sm-3" v-for="(theme, i) in course.themes" :key="theme.id" v-if="!isHidden"
                                         :class="[selectedMobile == theme.id ? 'active':'']" @click="makeActiveMobile(theme.id)" v-show="theme.modules.length > 0">
                                        <picture>
                                            <source media="(max-width:480px)" :srcset="theme.mobileImageUrl" @error="imageLoadOnError" v-if="theme.mobileImageUrl.length > 0">
                                            <img :src="theme.desktopImageUrl" :alt="theme.name" class="category-icon" @error="imageLoadOnError">
                                        </picture>
                                        <Highlighter class="my-highlight"
                                                     highlightClassName="highlight"
                                                     :searchWords="keywords"
                                                     :autoEscape="true"
                                                     :textToHighlight="theme.name" />
                                    </div>
                                </div>
                                <div class="content">
                                    <div v-if="loadingModules" class="text-center vh-50 p-5 m-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#14559E"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                                    </div>
                                    <div class="item" v-for="(theme, i) in course.themes" :key="theme.id" v-if="!isLessons" else>
                                        <div class="bg-white" @click="hideLessons">
                                            <button type="button" class="back white" v-show="selectedMobile === theme.id" style="background-color: white;">
                                                <svg width="22" height="11" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 243.58" fill="#14559E">
                                                    <path fill-rule="nonzero" d="M138.43 243.58 0 122.84 140.47 0l20.92 23.91-94.92 83 445.53-.42v31.75l-445.54.41 92.89 81.02z" />
                                                </svg>
                                                <span>back</span>
                                            </button>
                                        </div>
                                        <div class="module-wrapper" v-for="(module , i) in theme.modules" :key="module.id" v-if="selectedMobile == theme.id">
                                            <div v-if="module.lessons.length > 0">
                                                <div class="V-accordion_trigger" :class="{active: openMobile === i}" @click="toggleItemMobile(i)">
                                                    <h4 class="text-uppercase">
                                                        <span class="mw-80">
                                                            <Highlighter class="my-highlight"
                                                                         highlightClassName="highlight"
                                                                         :searchWords="keywords"
                                                                         :autoEscape="true"
                                                                         :textToHighlight="module.name" />
                                                        </span>
                                                        <svg class="V-accordion_trigger-icon" width="40" height="12">
                                                            <polyline points="12,10 20,2 28,10" stroke="#44464B" stroke-width="2" fill="none"></polyline>
                                                        </svg>
                                                    </h4>
                                                    <p v-if="module.description">
                                                        <Highlighter class="my-highlight"
                                                                     highlightClassName="highlight"
                                                                     :searchWords="keywords"
                                                                     :autoEscape="true"
                                                                     :textToHighlight="module.description" />
                                                    </p>
                                                </div>
                                                <div class="V-accordion_body" v-if="openMobile === i">
                                                    <div class="lessons d-flex align-content-between" v-for="(lesson, i) in module.lessons" :key="lesson.id">
                                                        <div class="lesson-left">
                                                            <h5>
                                                                <Highlighter class="my-highlight"
                                                                             highlightClassName="highlight"
                                                                             :searchWords="keywords"
                                                                             :autoEscape="true"
                                                                             :textToHighlight="lesson.name" />
                                                            </h5>
                                                            <div class="d-flex align-items-center">
                                                                <div v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                                    <div v-if="content.fileType == 'Audio'">
                                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/microphone_djfrek.svg" alt="audio" />
                                                                    </div>
                                                                    <div v-if="content.fileType == 'Pdf'">
                                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/document_eoybse.svg" alt="pdf" />
                                                                    </div>
                                                                    <div v-if="content.fileType == 'Video'">
                                                                        <img src="https://res.cloudinary.com/dqwmahefm/image/upload/v1685026956/pawpedia/static/video_hzxeqf.svg" alt="video" />
                                                                    </div>
                                                                </div>
                                                                <div class="pe-3">
                                                                    <h6 v-for="(content, i) in lesson.contents.slice(0,1)" :key="content.id">
                                                                        <span v-show="content.fileType == 'Audio'">{{lesson.duration}} minute listen</span>
                                                                        <span v-show="content.fileType == 'Pdf'">{{lesson.duration}} minute read</span>
                                                                        <span v-show="content.fileType == 'Video'">{{lesson.duration}} minute video</span>
                                                                    </h6>
                                                                    <div class="copy">
                                                                        <Highlighter class="my-highlight"
                                                                                     highlightClassName="highlight"
                                                                                     :searchWords="keywords"
                                                                                     :autoEscape="true"
                                                                                     :textToHighlight="lesson.description" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <!--<button class="red text-uppercase" @click="dialogState = true" v-if="!this.userLogged">Let's Go</button>-->
                                                            <button class="red text-uppercase" @click="goToLesson(course.id,theme.id,module.id,lesson.id)">Let's Go</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <GDialog v-model="dialogState">
        <div class="form-wrapper">
            <div class="container">
                <Form class="no-lr-padding" @submit="handleLogin(course.id)" :validation-schema="schema" v-slot="{ errors }">
                    <div class="details">
                        <button class="close"
                                @click.prevent="dialogState = false">
                            x
                        </button>
                        <h2>REGISTER OR LOGIN TO CONTINUE. <br />IT'S FREE!</h2>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <Field type="email" v-model="model.userName" id="name" name="username" class="form-control rounded-0" :class="{ 'is-invalid': errors.username }" />
                                    <label for="name">Email Address *</label>
                                    <div class="invalid-feedback">{{errors.username}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <Field type="password" v-model="model.password" id="password" name="password" class="form-control rounded-0" :class="{ 'is-invalid': errors.password }" />
                                    <label for="name">Password *</label>
                                    <div class="invalid-feedback">{{errors.password}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="dmessage">
                            <div class="redColour" v-if="dmessage">
                                {{ dmessage }}
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-primary rounded-0 px-4" :disabled="loginloading">
                                <span v-show="loginloading" class="spinner-border spinner-border-sm"></span>
                                <span>Login</span>
                            </button>
                        </div>
                        <div class="divider"><span>OR</span></div>
                        <div><a href="/register" class="btn btn-outline-primary rounded-0">Register</a></div>
                        <div><a href="/forgot-password" class="text-medium">Forgot password?</a></div>
                    </div>
                </Form>
            </div>
        </div>
    </GDialog>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import axios from 'axios'
    import apiUrl from '../scripts/BaseUrl';

    import { GDialog } from 'gitart-vue-dialog'
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import Highlighter from 'vue-highlight-words';

    const courseId = location.pathname.split('/')[2]
    //const queryString = window.location.search;
    //const urlParams = new URLSearchParams(queryString);

    //const user = JSON.parse(localStorage.getItem('user') || '');
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);

    export default defineComponent({
        name: "course",
        components: {
            GDialog,
            Form,
            Field,
            axios,
            Highlighter
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required('Email is required').email('Email is invalid'),
                password: yup.string().required("Password is required!"),
            });
            return {
                schema,
                course: '',
                desktopAsset: undefined,
                mobileAsset: undefined,
                selected: '',
                selectedMobile: '',
                message: '',
                loadingModules: false,
                modules: [] as any,
                lessonContent: [] as any,
                loading: false,
                dialogState: false,
                loginloading: false,
                dmessage: '',
                model: {
                    userName: '',
                    password: '',
                },
                isHidden: false,
                isLessons: false,
                open: 0,
                openMobile: 0,
                words: '',
                searchFilter: '',
                courseId: '',
                moduleId: '',
                themeId: '',
                lessonId: '',

            };
        },
        methods: {
            openModal(courseId: any) {
                this.courseId = courseId;
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/course/' + courseId;
                } else {
                    this.dialogState = true;
                }
            },
            handleLogin(courseId: any) {
                this.loading = true;
                const authData = {
                    auth:
                    {
                        username: this.model.userName,
                        password: this.model.password
                    }
                }
                const authToken = window.btoa(this.model.userName + ':' + this.model.password);
                return axios.post(apiUrl + '/accounts', this.model, authData)
                    .then((response) => {
                        let user = response.data;
                        user.token = authToken;
                        localStorage.setItem('user', JSON.stringify(user));
                        if (user.deactivatedAt) {
                            this.dialogState = true;
                            this.dmessage = 'User have been Deactivated';
                        } else {
                            this.dialogState = false;
                            this.loading = true;
                            window.location.href = '/school#/course/' + courseId;
                        }

                    }, (error) => {
                        this.loading = false;
                        this.dialogState = true;
                        if (error.response) {
                            if (error.response.data) {
                                this.dmessage = error.response.data;
                            } else {
                                this.dmessage = 'An error occured. contact your system administrator';
                            }

                        } else {
                            this.message = error.response.data.message;
                        }
                    });
            },

            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1671006754/pawpedia/static/image_not_available_tqbecm.png"
            },
            async displayModules(themeId: any) {
                this.loadingModules = true;
                return axios.get(apiUrl + '/themes/' + themeId)
                    .then((response) => {
                        this.loadingModules = false;
                        this.modules = response.data.modules;
                        return response;
                    })
                    .catch(error => {
                        this.loadingModules = false;
                        return error;
                    })

            },
            makeActive(themeId: any) {
                this.selected = themeId;
                this.open = 0;
                sessionStorage.setItem("CourseId", JSON.stringify(courseId));
                sessionStorage.setItem("ThemeId", JSON.stringify(themeId));
            },
            makeActiveMobile(themeId: any) {
                this.openMobile = 0;
                this.isHidden = true;
                this.isLessons = false;
                this.selectedMobile = themeId;
                sessionStorage.setItem("CourseId", JSON.stringify(courseId));
                sessionStorage.setItem("ThemeId", JSON.stringify(themeId));
            },
            hideLessons() {
                this.isLessons = true;
                this.isHidden = false;
            },
            goToLesson(courseId: any, themeId: any, moduleId: any, lessonId: any) {
                const courseObjectSchool = {
                    enrolId: '',
                    courseId: courseId,
                    moduleId: moduleId,
                    themeId: themeId,
                    lessonId: lessonId,
                }
                window.location.href = '/school#/course/' + courseId;                
            },
            toggleItem(index: any) {
                this.open = this.open === index ? null : index;
            },
            toggleItemMobile(i: any) {
                this.openMobile = this.openMobile === i ? null : i;
            },
            getCourseSchoolObject() {
                const courseData = JSON.parse(window.sessionStorage.getItem("courseObjectSchool") || '[]');
                if (courseData) {
                    this.selected = courseData.themeId;
                } else {

                }
            },
            checkUserExists() {
                if (localStorage.getItem("user")) {
                    window.location.href = '/school#/course/'+ courseId;
                }
            },
        },
        async mounted() {
            this.loading = true;
            await axios
                .get(apiUrl + '/courses/' + courseId + '/summarized')
                .then((response) => {
                    this.loading = false;
                    this.course = response.data;
                    this.desktopAsset = response.data.desktopImageUrl;
                    this.mobileAsset = response.data.mobileImageUrl;
                    if (urlParams.has('search') && sessionStorage.getItem('courseObjectSchool')) {
                        let courseObjectSchoolWrapper = JSON.parse(window.sessionStorage.getItem("courseObjectSchool") || '');
                        this.selected = courseObjectSchoolWrapper.themeId;
                    } else {
                        this.selected = response.data.themes[0].id;
                    }

                })
                .catch((error) => {
                    this.loading = false;
                    return error;

                });

            if (urlParams.has('search')) {
                this.searchFilter = urlParams.get('search')!;
            }
        },
        created() {
            this.getCourseSchoolObject();
            this.checkUserExists();
        },
        computed: {
            keywords() {
                this.words = (this.searchFilter!).toString()
                return this.words.split(' ')
            },
        },
    });
</script>


