<template>
    <div v-if="loading" class="text-center loading p-5 m-5">     
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height="50" width="50" fill="#FFFFFF"><rect x="0" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
    </div>
    <div else>
        <div class="swiper-wrap" v-if="coaches.length > 0">
            <swiper :modules="modules"
                    :direction="horizontal"
                    :slides-per-view="4"
                    :space-between="10"
                    :slides-offset-before="0"
                    :slides-offset-ofter="0"    
                    :watch-overflow="true"
                    :center-insufficient-slides="true"
                    :set-wrapper-size="true"
                    :navigation="swiperOptions.navigation"
                    :breakpoints="swiperOptions.breakpoints"
                    :touchMoveStopPropagation="false"
                    @swiper="onSwiper"
                    class="coaches-container">
                <swiper-slide v-for="coach of coaches" :key="coach.id">  
                    <a :href="'coaches#coach-'+coach.id" class="text-decoration-none">                  
                        <span class="circle-image">
                            <picture else>
                                <source media="(max-width:480px)" :srcset="coach.mobileImageUrl" @error="imageLoadOnError" v-if="coach.mobileImageUrl.length>0">
                                <img :src="coach.desktopImageUrl" :alt="coach.firstName" class="img-fluid" @error="imageLoadOnError">
                            </picture>
                        </span>
                        <div class="py-3">
                            <h5>{{coach.fullname}}</h5>       
                        </div>       
                    </a>
                </swiper-slide>
            </swiper>
            <div tabindex="0" class="swiper-button-prev coaches-prev" role="button"></div>
            <div tabindex="0" class="swiper-button-next coaches-next" role="button"></div>
        </div>
        <div v-if="coaches.length == 0">
            <h4 class="text-center text-white">No coaches to display</h4>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
    import { Swiper, SwiperSlide } from "swiper/vue";
    SwiperCore.use([Navigation, Pagination, A11y]);
    import axios from "axios";
    import apiUrl from '../scripts/BaseUrl';
    export default defineComponent({
        name: "CoachesSlider",
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                loading: false,
                coaches: [] as any,       
                swiperOptions: {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        }
                    }
                }
            };
        },
        methods: {
            imageLoadOnError(event: any) {
                event.target.src = "https://res.cloudinary.com/dqwmahefm/image/upload/v1671006754/pawpedia/static/image_not_available_tqbecm.png"
            },
            goTo(id: any) {
                window.location.href = '/coaches#coach-'+id;

            },
           
        },      
        async mounted() {
            this.loading = true;           
            await axios
                .get(apiUrl + "/coaches/summarized")
                .then((response) => {
                    this.loading = false;
                    this.coaches = response.data.items;
                })
                .catch((error) => {
                    return error;
                });
        },
        
    })
</script>

<style>
    #coaches {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
</style>
